<template>
  <b-container fluid>
    <b-row>
      <b-col class="allCardList" md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              <i :class="`fa-solid fa-chart-gantt`" aria-hidden="true" style="font-size: 21px; margin-right: 10px; color: var(--iq-primary);"></i>
              <span>{{cvCardTitle}}</span>
              <span class="btn_in_header">
                <span class="pull-right">
                  <b-button variant="primary" @click="spcm_milestonesAdd">{{cvbtnAddNew}}</b-button>
                </span>
              </span>
            </h4>
          </template>
          <!-- <template v-slot:headerAction>
            <b-button variant="primary" @click="spcm_milestonesAdd">{{cvbtnAddNew}}</b-button>
          </template> -->
          <template v-slot:body>
            <b-row class="row w-100">
              <!-- Frontend Search -->
              <!-- <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>Frontend Search -->

              <!-- Backend Search -->
              <b-col class="col-12 col-sm-7 col-md-6 col-lg-4 mb-2">
                <b-form-input class="form-control"
                  @input="searchspcm_milestonesWithParams"
                  v-model="search_param"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col><!-- Backend Search -->
              <!-- Partnership Type -->
              <b-col class="col-12 col-sm-4 col-md-2 col-lg-2 mb-2">
                <select v-model="whereFilter.partnership_type" class="form-control" @change="setOptionFilter">
                  <option value="">All</option>
                  <option value="Partner">Partner</option>
                  <option value="Premium">Premium</option>
                </select>
              </b-col>
              <!-- Country Strategy -->
              <b-col class="col-12 col-sm-4 col-md-2 col-lg-2 mb-2">
                <select v-model="whereFilter.country_strategy" class="form-control" @change="setOptionFilter">
                  <option value="USA">USA</option>
                  <option value="UK">UK</option>
                  <option value="CANADA">CANADA</option>
                  <option value="OXBRIDGE">OXBRIDGE</option>
                  <option value="SINGAPORE">SINGAPORE</option>
                  <option value="AUSTRALIA">AUSTRALIA</option>
                </select>
              </b-col><!-- Country Strategy -->
              <!-- Board-->
              <b-col class="col-12 col-sm-4 col-md-2 col-lg-2 mb-2">
                <select v-model="whereFilter.board" class="form-control" @change="setOptionFilter">
                  <option value="CBSE">CBSE</option>
                  <option value="IB">IB</option>
                  <option value="IGCSE">IGCSE</option>
                  <option value="ALL">ALL</option>
                </select>
              </b-col><!-- Board -->
               <!-- Class-->
               <b-col class="col-12 col-sm-4 col-md-2 col-lg-2 mb-2">
                <select v-model="whereFilter.class" class="form-control" @change="setOptionFilter">
                  <option value="">All</option>
                  <option value="12">12</option>
                  <option value="11">11</option>
                  <option value="10">10</option>
                  <option value="9">9</option>
                  <option value="8">8</option>
                </select>
              </b-col><!-- Class -->
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="spcmMilestonesObjList && spcmMilestonesObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="spcmMilestonesObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                >
                  <template v-slot:cell(created_on)="data">
                    {{data.item.created_on | dateFormat}}
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="spcm_milestonesEdit('MODAL', data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-danger" @click="showSpcm_milestonesDeleteDialog(data.item)" size="sm"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-col sm="12" md="12" class="my-1">
                <b-row>
                  <b-col sm="4" md="7">
                    <b-btn class="mb-2" variant="primary" @click="loadMoreData" v-if="showLoadMoreBtn">Load More</b-btn>
                  </b-col>
                </b-row>
              </b-col>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelSpcmMilestonesAdd"
      scrollable
      :title="cvAddModalHeader"
    >
      <spcmMilestonesAdd :propOpenedInModal="true" @emitCloseSpcm_milestonesAddModal="closeSpcm_milestonesAddModal" />
      <template #modal-footer="">
        <b-button size="sm" @click="closeSpcm_milestonesAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelSpcmMilestonesEdit"
      scrollable
      :title="cvEditModalHeader"
    >
      <spcmMilestonesEdit :propOpenedInModal="true" :propSpcm_milestonesObj="spcmMilestonesEditObj" @emitCloseSpcm_milestonesEditModal="closeSpcm_milestonesEditModal" />
      <template #modal-footer="">
        <b-button size="sm" @click="closeSpcm_milestonesEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelSpcmMilestonesDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelSpcmMilestonesDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="spcm_milestonesDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { spcmMilestones } from "../../../FackApi/api/Spcmmilestones.js"
import spcmMilestonesAdd from "./SpcmMilestonesAdd"
import spcmMilestonesEdit from "./SpcmMilestonesEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit.js"

export default {
  name: "spcmMilestonesList",
  components: {
    spcmMilestonesAdd,
    spcmMilestonesEdit
  },
  data () {
    return {
      cvCardTitle: "Counselling Milestones",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit Counselling Milestones",
      cvAddModalHeader: "Add Counselling Milestones",
      cvbtnModalCancel: "Cancel",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Counselling Milestones",
      showModelSpcmMilestonesAdd: false,
      showModelSpcmMilestonesEdit: false,
      showModelSpcmMilestonesDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },

        { label: "student country ", key: "ms_student_country ", class: "text-left align-text-top", sortable: true },
        { label: "board", key: "ms_board", class: "text-left align-text-top", sortable: true },
        { label: "class", key: "ms_class", class: "text-left align-text-top", sortable: true },
        { label: "univ partnership type", key: "ms_univ_partnership_type", class: "text-left align-text-top", sortable: true },
        { label: "country strategy", key: "ms_country_strategy", class: "text-left align-text-top", sortable: true },
        { label: "months", key: "ms_months", class: "text-left align-text-top", sortable: true },
        { label: "title", key: "ms_title", class: "text-left align-text-top", sortable: true },
        { label: "desc", key: "ms_desc", class: "text-left align-text-top", sortable: true },
        { label: "reading reco", key: "ms_reading_reco", class: "text-left align-text-top", sortable: true },
        { label: "reading reco url", key: "ms_reading_reco_url", class: "text-left align-text-top", sortable: true },
        { label: "Action ", key: "action", class: "text-left align-text-top", sortable: false }
      ],
      spcmMilestonesObjList: [],
      spcmMilestonesEditObj: null,
      filter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 10,
      delObj: null,
      whereFilter: {},
      partnership_type: "",
      country_strategy: "",
      board: "",
      class: "",
      search_param: "",
      showLoadMoreBtn: true
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async mounted () {
    socialvue.index()
    this.spcm_milestonesList()
  },
  methods: {
    /**
     * searchspcm_milestonesWithParams
     */
    async searchspcm_milestonesWithParams () {
      if (this.whereFilter.search_param.length == 0) {
        this.showLoadMoreBtn = true
      }
      this.spcm_milestonesList()
    },
    /**
     * loadMoreData
     */
    async loadMoreData () {
      this.spcm_milestonesList(true)
    },
    /**
     * setOptionFilter
    */
    async setOptionFilter () {
      this.spcm_milestonesList()
    },
    /**
     * spcm_milestonesList
     */
    async spcm_milestonesList (loadViaLoadMore = false) {
      try {
        this.whereFilter.search_param = this.search_param

        let spcmMilestonesListResp = await spcmMilestones.spcm_milestonesList(this, this.whereFilter)
        if (spcmMilestonesListResp.resp_status) {
          if (this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
            this.spcmMilestonesObjList = [ ...spcmMilestonesListResp.resp_data.data ]
          }
          else {
            this.spcmMilestonesObjList = [ ...this.spcmMilestonesObjList, ...spcmMilestonesListResp.resp_data.data ]
          }
          this.showLoadMoreBtn = true

          // Adding the serial numbers:
          this.spcmMilestonesObjList.forEach((s, index) => { s.id = index + 1 })
        }
        else {
          this.showLoadMoreBtn = false
          this.toastMsg = spcmMilestonesListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at spcm_milestonesList() and Exception:", err)
      }
    },
    /**
     * spcm_milestonesAdd
     */
    spcm_milestonesAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/spcm_milestones_add")
        }
        else {
          this.showModelSpcmMilestonesAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at spcm_milestonesAdd() and Exception:", err.message)
      }
    },
    /**
     * spcm_milestonesEdit
     */
    spcm_milestonesEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/spcm_milestones_edit/" + this.spcmMilestonesEditObj.ms_id)
        }
        else {
          this.spcmMilestonesEditObj = item
          this.showModelSpcmMilestonesEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at spcm_milestonesEdit() and Exception:", err.message)
      }
    },
    /**
     * showSpcm_milestonesDeleteDialog
     */
    showSpcm_milestonesDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelSpcmMilestonesDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showSpcm_milestonesDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * spcm_milestonesDelete
     */
    async spcm_milestonesDelete () {
      try {
        let spcmMilestonesDelResp = await spcmMilestones.spcm_milestonesDelete(this, this.delObj.ms_id)
        await ApiResponse.responseMessageDisplay(this, spcmMilestonesDelResp)

        if (spcmMilestonesDelResp && !spcmMilestonesDelResp) {
          this.showModelSpcmMilestonesDelete = false
          return false
        }

        let index = this.spcmMilestonesObjList.indexOf(this.delObj)
        this.spcmMilestonesObjList.splice(index, 1)
        this.showModelSpcmMilestonesDelete = false
      }
      catch (err) {
        console.error("Exception occurred at spcm_milestonesDelete() and Exception:", err.message)
      }
    },
    /**
     * closeSpcm_milestonesAddModal
     */
    closeSpcm_milestonesAddModal (spcmMilestonesAddData) {
      try {
        if (spcmMilestonesAddData) {
          if (this.spcmMilestonesObjList && this.spcmMilestonesObjList.length >= 1) {
            let spcmMilestonesObjLength = this.spcmMilestonesObjList.length
            let lastId = this.spcmMilestonesObjList[spcmMilestonesObjLength - 1]["id"]
            spcmMilestonesAddData.id = lastId + 1
          }
          else {
            this.spcmMilestonesObjList = []
            spcmMilestonesAddData.id = 11111
          }

          spcmMilestonesAddData.created_on = moment()
          this.spcmMilestonesObjList.unshift(spcmMilestonesAddData)
        }

        this.showModelSpcmMilestonesAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closespcm_milestonesAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeSpcm_milestonesEditModal
     */
    closeSpcm_milestonesEditModal () {
      try {
        this.showModelSpcmMilestonesEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeSpcm_milestonesEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
